//*----------  CHARTS  ----------*/
import {
    DxChart,
    DxSeries,
    DxLegend
} from 'devextreme-vue/chart';

import {
    mapActions,
    mapGetters,
    mapState
} from 'vuex';

var currentDate = new Date().toISOString().substr(0, 10);
export default {
    name: "Ticket",
    components: {
        DxSeries,
        DxChart,
        DxLegend
    },
    props: {
        dataConcepto: Object
    },
    data: () => ({
    }),
    computed: {
        ...mapGetters("dashboard", {
            dataCerrados: "getDataCerradosResponsable"
        }),
    },
}